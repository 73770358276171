/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import * as styles from "./styles.module.scss"
import '@fontsource/roboto-mono'
import '@fontsource/open-sans'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div className={styles.mediaqueries}>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            <div>
                <main>{children}</main>
                <footer>
                    <div>
                        © {new Date().getFullYear()}, Built with
                        {` `}
                        <a href="https://www.gatsbyjs.com">Gatsby</a>
                    </div>
                    <div>Email: <a onClick={() => window.location.href = "mailto:dnkkihe@gmail.com"}>dnkkihe@gmail.com</a></div>
                    <div>Github: <a onClick={() => window.location = "https://github.com/dkihe"}>github.com/dkihe</a></div>
                </footer>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
