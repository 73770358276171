import * as React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as styles from './styles.module.scss'

const Header = ({ siteTitle }) => (
    <header>
        <div className={styles.headercontainer}>
            <div className={styles.innerheader}>
                <div className={styles.title}> <AnchorLink to='/#about'> { siteTitle } </AnchorLink></div>
                {/* <div className={styles.nav}> */}
                    <nav>        
                        <AnchorLink to='/#about'>About</AnchorLink>
                        <AnchorLink to='/#projects'>Projects</AnchorLink>          
                    </nav>
                {/* </div> */}
            </div>
        </div>
    </header>
)

export default Header
